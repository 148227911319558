<template>
  <div v-if="isCollapseInDOM" ref="collapse">
    <slot></slot>
  </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
// @ts-ignore
import { Collapse as BSCollapse } from 'bootstrap';

@Component({
  components: {}
})
export default class Collapse extends Vue {

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly value!: boolean

  isCollapseInDOM:boolean = false;

  show() {
    // We add the collapse in the DOM
    this.isCollapseInDOM = true;
    // We wait for DOM to be rendered
    Vue.nextTick(() => {
      // We create the instance of the modal
      var collapseInstance = BSCollapse.getOrCreateInstance(this.$refs.collapse as HTMLElement);
      // We attach the hidden event
      (this.$refs.collapse as HTMLElement).addEventListener('hidden.bs.collapse', (event:Event) => {
        // We destroy the modal instance
        collapseInstance.dispose();
        // We remove the modal from the DOM
        this.isCollapseInDOM = false;
        // We update the v-model
        this.$emit('input', false);
      });

      // We show the modal
      collapseInstance.show();
      // We update the v-model
      this.$emit('input', true);
    })
  }

  hide() {
    // We get the instance of the modal
    var collapseInstance = BSCollapse.getOrCreateInstance(this.$refs.collapse as HTMLElement);
    collapseInstance.hide();
  }

  @Watch('value', { deep: true })
  onOpenedChange(value: any, oldVal: any) {
    if(value) {
      this.show();
    }
    else {
      this.hide();
    }
  }
  
}
</script>