<template>
    <div :class="'sticky-cart'+ (opened ? ' opened':'')" v-if="cartProducts.length > 0 || !computedOptions.hideWhenEmpty">
        <Collapse v-model="opened" class="details">
            <div class="container-md">
                <div class="empty" v-if="cartProducts.length == 0 && cartActivities.length == 0">{{ $cms.utils.getComputedLabel(computedLabels.cartEmpty) }}</div>
                <Products v-if="computedOptions.type == 'shop' && cartProducts.length > 0" :products="cartProducts"></Products>
                <Activities v-if="computedOptions.type == 'shop' && cartActivities.length > 0" :products="cartActivities"></Activities>
                <Rentals v-if="computedOptions.type == 'hospitality' && cartProducts.length > 0" :options="{products: cartProducts}"></Rentals>
            </div>
        </Collapse>
        
        <div class="summary">
            <div class="container-md">
                <div class="title">
                    <a href="javascript:void(0)" @click="onCartDetailsClick" class="details">
                        <i class="icon-shopping-cart"></i>
                        <span>{{ $cms.utils.getComputedLabel(computedLabels.title) }}</span>
                        <i class="icon-chevron-up1"></i>
                    </a>
                </div>
                <div class="total">
                    <span v-if="cartProducts.length > 0">{{ $cms.utils.getComputedLabel(computedLabels.total) }} {{ formatPriceAmount(subTotal) }}</span>
                </div>
                <button :disabled="cartProducts.length == 0 && cartActivities.length == 0" :class="'button m-0' + (opened ? ' button-3d' : '')" @click="onCheckoutClick">
                    {{ $cms.utils.getComputedLabel(computedLabels.checkout) }}
                    <i class="icon-circle-arrow-right"></i>
                </button>
            </div>
        </div>

        

    </div>
</template>

<style>

.sticky-cart {
    position:-webkit-sticky;
    position:sticky;
    bottom:0px;
    border-top: 2px solid var(--secondary-color);
    background-color: var(--content-background-color);
    z-index: 50;
}

.sticky-cart.opened .summary {
    padding-bottom: 10px;
}

.sticky-cart .summary .container-md {
    display:flex;
    flex-wrap: wrap;
}

.sticky-cart .summary .title, .sticky-cart .summary .total {
    text-transform: uppercase;
    line-height: 34px;
    font-weight: 600;
}

.sticky-cart .summary .title a.details i:first-child {
    margin-right:10px;
}

.sticky-cart .summary .title a.details i:last-child {
    margin-left:10px;
    transition: 0.3s ease all;
}

.sticky-cart.opened .summary .title a.details i:last-child {
    transform: rotate(180deg);
}

.sticky-cart .summary .total {
    flex-grow:1;
    text-align: center;
}

@media (max-width: 575.98px) {
    .sticky-cart .summary {
        padding-bottom: 10px;
    }
    
    .sticky-cart .summary .title {
        flex-grow: 1;
    }

    .sticky-cart .summary .total {
        flex-grow: 0;
    }

    .sticky-cart .summary .button {
        width:100%;
    }
}

.sticky-cart .details .empty {
    padding:10px;
    text-align: center;
}

.sticky-cart .details .table th {
    border-top:0px;
}

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, Ref, ref, computed } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useCart } from '@root/src/client/composables/useCart'

import Collapse from '@fwk-client/components/panels/bootstrap/Collapse.vue';


/** @cmsOptions */
export interface StickyCartOptions {
    /** @cmsType CmsBoolean */
    hideWhenEmpty?:CmsBoolean;
    /** @cmsType CmsEnum */
    type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface StickyCartLabels {
    /** @cmsType CmsLabel */
    cartEmpty:CmsLabel
    /** @cmsType CmsLabel */
    title:CmsLabel
    /** @cmsType CmsLabel */
    total:CmsLabel
    /** @cmsType CmsLabel */
    checkout:CmsLabel
}

export const defaultLabels:StickyCartLabels = {
    cartEmpty : {
        fr : "Votre panier est vide",
        de : "Ihr Warenkorb ist leer",
        en : "Your cart is empty"
    },
    title : {
        fr : "Votre panier",
        de : "Ihr Warenkorb",
        en : "Your cart"
    },
    total : {
        fr : "Total:",
        de : "Gesamt:",
        en : "Total:"
    },
    checkout : {
        fr : "Commander",
        de : "Befehl",
        en : "Checkout",
    }
}

/** @cmsSlots */
export interface StickyCartSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<StickyCartOptions>,
        labels: {
          type: Object as PropType<StickyCartLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<StickyCartSlots>
    },
    components : {
        Products : () => import(/* webpackChunkName: "panels-checkout-cart-products" */ '@root/src/client/components/panels/shop/container/Products.vue'),
        Activities : () => import(/* webpackChunkName: "panels-checkout-cart-activities" */ '@root/src/client/components/panels/shop/container/Activities.vue'),
        Rentals : () => import(/* webpackChunkName: "panels-checkout-cart-rentals" */ '@root/src/client/components/panels/hospitality/container/Rentals.vue'),
        Collapse
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:StickyCartOptions = {
            hideWhenEmpty: false,
            type: "shop",
            ...props.options,
        };

        const { onBackToListClick, onCheckoutClick, cartProducts, cartActivities, subTotal } = useCart({
            type: computedOptions.type!
        }, context);

        const computedLabels:StickyCartLabels = {
            ...defaultLabels,
            ...props.labels
        }
        
        const opened:Ref<boolean> = ref(false);

        const onCartDetailsClick = (evt:Event) => {
            evt.preventDefault();
            opened.value = !opened.value;
        }


        

        return {
            opened,
            onCartDetailsClick,
            onBackToListClick,
            onCheckoutClick,
            computedLabels,
            computedOptions,
            cartProducts,
            cartActivities,
            subTotal
        }
    }
})
</script>